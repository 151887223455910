import React from "react";
import { Container } from "@mui/material";
import { AppBar } from "@mui/material";
import facebook from "../../assets/icons/facebook.svg";
import insta from "../../assets/icons/insta.svg";
import twitter from "../../assets/icons/twitter.svg";
import whatsapp from "../../assets/icons/whatsapp.svg";
import { Icon } from "@mui/material";
import { Email } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <AppBar position="static" sx={{
      backgroundColor: "primary.dark"
    }}>
      <Container maxWidth="xl">
        <div className="site-footer">
          <div>
            <Icon sx={{ my: 1, fontSize: "150px" }}>
              <img src="/images/cfaLogo.svg" style={{ width: "100%" }} />
            </Icon>
            <span style={{
              display: "block",
              margin: "0",
              padding: "0",
              textAlign: "center",
              fontSize: "0.7em",
              marginTop: "-20px",
              marginBottom: "15px",
            }}>FOR LEABANON</span>
          </div>
          <div className={"social"}>

            <a href="https://wa.me/+4546543" target="_blank">
              <img src={whatsapp} />
            </a>

            <a href="https://www.instagram.com/cfa/" target="_blank">
              <img src={insta} />
            </a>

            <a href="https://twitter.com/cfa" target="_blank">
              <img src={twitter} />
            </a>

            <a href="https://www.facebook.com/cfa/" target="_blank">
              <img src={facebook} />
            </a>

          </div>

          <a href="mailto:info@cfa-lb.com" style={{ color: "#FFF", marginTop: "20px", marginBottom: "5px", display: "flex", gap: "10px" }}><Email />info@cfa-lb.com</a>
          <div style={{ margin: 0, marginTop: "5px",fontSize:"0.6em" }}>
            <span>{t("footer.associationNumber")}</span>
            <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>١١٥٦ / ودب ٢٠٢٤</span>
            <span>"{t("footer.underProgress")}"</span>
          </div>
          <h3 style={{ margin: 0, marginTop: "5px" }}>© {new Date().getFullYear()} {t("footer.legalRights")}</h3>
        </div>
      </Container>
    </AppBar>
  );
};

export default Footer;
